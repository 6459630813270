<template>
  <div style="background-color:gray; margin-top:-128px; height:128px;">
    <iframe :src="url" class="iframe"></iframe>
		<!-- <footerComponent :backgroundImage="footerImageLG"></footerComponent> -->
  </div>
</template>
<script>
// import footerComponent from './components/Footer'
import { mapState } from "vuex";
const baseURL = process.env.VUE_APP_BASE_URL_API;
export default {
		// components: { footerComponent },
    data: () => ({
			pageTitle:['Fotógrafo profesional en Medellín - Karlus Morales Fotografía','Professional Photographer in Medellin - Karlus Morales Photography'],
			pageMetaTitle:['Fotógrafo profesional en Medellín | Servicios de fotografía de Karlus Morales','Professional Photographer in Medellin | Photography Services by Karlus Morales'],
			pageDescription: ['Soy un fotógrafo profesional en Medellín que ofrece una amplia variedad de servicios de fotografía para inmortalizar tus momentos especiales. Visita mi portafolio y recuerda tus momentos especiales por siempre.','I am a professional photographer in Medellin offering a wide range of photography services to capture your special moments. Visit my portfolio and remember your special moments forever.'],
			pageKeywords:['Fotógrafo profesional, Medellín, Servicios de fotografía, Karlus Morales, Fotografías de calidad','Professional Photographer, Medellin, Photography Services, Karlus Morales, Quality Photographs'],
			metaImage:'',
			metaImageID:104,
			requiresReload:false,
    }),
    computed:{
      ...mapState("shoppingCart", 
        [
          "languageId","images","artPiecesOk",
        ]),
      url(){
        return `${baseURL}Portfolio/portfolio.html`
      }
    },
	metaInfo(){
		return{
			title: this.pageTitle[this.languageId],
			meta: [
				{
				vmid: 'robots',
				name: 'robots',
				content: 'index, follow',
				},
				{
				vmid: 'title',
				name: 'title',
				content: this.pageMetaTitle[this.languageId],
				},
				{
				vmid: 'description',
				name: 'description',
				content: this.pageDescription[this.languageId],
				},
				{
				vmid: 'keywords',
				name: 'keywords',
				content: this.pageKeywords[this.languageId],
				},
				{
				vmid: 'og:title',
				property: 'og:title',
				content: this.pageMetaTitle[this.languageId],
				},
				{
				vmid: 'og:description',
				property: 'og:description',
				content: this.pageDescription[this.languageId],
				},
				{
				vmid: 'og:image',
				property: 'og:image',
				content: this.metaImage,
				},
				{
				vmid: 'og:url',
				property: 'og:url',
				content: `${window.location.href}`,
				},
				{
				vmid: 'twitter:title',
				name: 'twitter:title',
				content: this.pageMetaTitle[this.languageId],
				},
				{
				vmid: 'twitter:description',
				name: 'twitter:description',
				content: this.pageDescription[this.languageId],
				},
				{
				vmid: 'twitter:image',
				name: 'twitter:image',
				content: this.metaImage,
				},
				{
				vmid: 'twitter:url',
				name: 'twitter:url',
				content: `${window.location.href}`,
				},
				{
				vmid: 'twitter:card',
				name: 'twitter:card',
				content: 'summary',
				},
			]
		}
	},
	methods:{		
		getImageById(idImage){
			if(this.images != null){
				let image = this.images.find(x=>x.id == idImage)
				return image.base64
			}
		},
		selectMetaImage(idImage){
			idImage = idImage!=null?idImage:this.metaImageID;
			let mImage = this.getImageById(idImage)
			if(mImage != null){
				return window.location.origin + require(`../assets/${mImage}`)
			}
        },
	},
	mounted(){
		if(this.artPiecesOk){
			this.metaImage = this.selectMetaImage(this.metaImageID);
		} else {
			this.requiresReload = true;
		}
	},
	updated(){
		if(this.requiresReload && this.artPiecesOk){
			this.metaImage = this.selectMetaImage(this.metaImageID);
			this.requiresReload = false;
		}
	}
}
</script>
<style scoped>
.iframe {
  width:100%;
  height:100vh;
  border:none;
  padding-top:128px;
}
</style>
